import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from 'mapbox-gl-geocoder';
import 'mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import ReactDOMServer from 'react-dom/server';
import toGeoJSON from './togeojson.js';
import './Map.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;



mapboxgl.accessToken =
  'pk.eyJ1IjoiYmxhbmNoYXJkc2ViIiwiYSI6ImNrbGp1am1mczBoMzEyb29icXNsdjc4NTcifQ.OwHwsqO5tv4nFFFgRKXfrg';
  
  
const useLocalStorage = (key, defaultValue) => {
  const stored = localStorage.getItem(key);
  const initial = stored ? JSON.parse(stored) : defaultValue;
  const [value, setValue] = useState(initial);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};


const Map = () => {
  const mapContainerRef = useRef(null);

  const [lng, setLng] = useLocalStorage( "lng", 5.9081);
  const [lat, setLat] = useLocalStorage("lat", 45.1205);
  const [zoom, setZoom] = useLocalStorage("zoom", 12.67);
  const [pitch, setPitch] = useLocalStorage("pitch", 0);
  const [bearing, setBearing] = useLocalStorage("bearing", 0);
  const [layers, setLayers] = useLocalStorage("layers", [ "summer","avalanches","slopesign" ]);
var altitudeDiv = document.createElement("div");

  
  
  class PitchToggle {
	  constructor() {
	  }

	  onAdd(map) {
		let _this = this;
		this._map = map;
	
	  	this.availablelayers = [ {id:"avalanches", name:"Avalanches"} , {id:"slopesign", name:"Pentes"} , {id:"summer", name:"Summer", exclude:["winter", "ign", "ombre"]}, {id:"winter", name:"Winter", exclude:["summer", "ign", "ombre"]}, {id:"ign", name:"IGN", exclude:["winter", "summer"], include:["ombre"]} ];
			
		let _container = document.createElement("div");
		_container.className = "mapboxgl-ctrl-group mapboxgl-ctrl mapbox-layer-ctrl";
		
		try {
		layers.forEach( _ => _this.toggleLayer(_, true));
		} catch (e) {}
		
		this._container = _container;
		this.redraw();
		return this._container;
	  }
	  
	  saveLayers(){
			let _this = this;
			var result = [];
	  		_this.availablelayers.forEach( _layer => {
				var visibility = _this._map.getLayoutProperty(_layer.id, 'visibility');
				// toggle layer visibility by changing the layout object's visibility property
				if (visibility != 'none') {
					result.push(_layer.id);
				} 
			});
			setLayers(result);
	  }
	  
	  toggleLayer(id, active){
			let _this = this;
			var _layer = this.availablelayers.find( _ => _.id == id);
			var visibility = _this._map.getLayoutProperty(_layer.id, 'visibility');
			// toggle layer visibility by changing the layout object's visibility property
			if (visibility != 'none' && !_layer.exclude && active === undefined) {
				_this._map.setLayoutProperty(_layer.id, 'visibility', 'none');
				if (_layer.include) {
					_layer.include.forEach( ex => _this._map.setLayoutProperty(ex, 'visibility', 'none') );
				}
			} else {
				_this._map.setLayoutProperty(_layer.id, 'visibility', 'visible');
				if (_layer.exclude) {
					_layer.exclude.forEach( ex => _this._map.setLayoutProperty(ex, 'visibility', 'none') );
				}
				if (_layer.include) {
					_layer.include.forEach( ex => _this._map.setLayoutProperty(ex, 'visibility', 'visible') );
				}
			}
	  }
	  
	  redraw(){
			let _this = this;
			_this._container.innerHTML="";
							var _btn = document.createElement("div");

				_this._container.appendChild(altitudeDiv);

			_this.availablelayers.forEach( layer => {
				var _layer=layer;
				var visibility = _this._map.getLayoutProperty(_layer.id, 'visibility');
				var _btn = document.createElement("div");
				_btn.className = visibility != 'none' ? 'active' : 'none';
				_btn.innerHTML = _layer.name;
				_btn.onclick = function() {
					_this.toggleLayer(layer.id);
					_this.redraw();
					_this.saveLayers();
				};
				_this._container.appendChild(_btn);
			});
	  }

	  onRemove() {
		this._container.parentNode.removeChild(this._container);
		this._map = undefined;
	  }
	}


  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/blanchardseb/ckljyhtnf0ybw17k8bl1tdo4o',
      center: [lng, lat],
      zoom: zoom,
	  pitch:pitch,
	  bearing:bearing,
	  antialias:true
    });

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
	map.addControl(
	new mapboxgl.GeolocateControl({
	positionOptions: {
	enableHighAccuracy: true
	},
	trackUserLocation: true
	}), 'bottom-right'
	);

	window.map=map;
	map.addControl(
		new MapboxGeocoder({
			accessToken: mapboxgl.accessToken,
			mapboxgl: mapboxgl,
			proximity : { latitude : parseFloat(lat), longitude : parseFloat(lng)}
		}), 'top-right'
	);

    map.on('move', () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
      setPitch(map.getPitch().toFixed(2));
      setBearing(map.getBearing().toFixed(2));
    });

	
	map.on('load', function () {
		
		map.addSource('winter' , {
				'type': 'raster',
				'tiles': [
					'/proxy/proxy.php?type=winter&z={z}&x={x}&y={y}'
				],
				'tileSize': 256,
				'maxzoom':18,
				'bounds':[0,41,8,47]
		});
		map.addSource('ign' , {
				'type': 'raster',
				'tiles': [
					'/proxy/proxy.php?type=ign&z={z}&x={x}&y={y}'
				],
				'tileSize': 256,
				"maxzoom": 16,
				'bounds':[0,41,8,47]
		});
		map.addSource('slopesign' , {
				'type': 'raster',
				'tiles': [
					'/proxy/proxy.php?type=slopesign&z={z}&x={x}&y={y}'		
				],
				'tileSize': 256,
				'maxzoom':17,
				'bounds':[0,41,8,47]
		});
		map.addSource('slopes' , {
				'type': 'raster',
				'tiles': [
					'/proxy/proxy.php?type=slopes&z={z}&x={x}&y={y}'		
				],
				'tileSize': 256,
				'maxzoom':15,
				'bounds':[0,41,8,47]
		});
		
		map.addLayer({
				'id': 'winter',
				'type': 'raster',
				'source': 'winter',
				'layout': {
					'visibility':'none'
				}
		}, 'contour');
		
			
		map.addLayer({
				'id': 'ign',
				'type': 'raster',
				'source': 'ign',
				'layout': {
					'visibility':'none'
				}
		}, 'contour');
		

		map.addLayer({
				'id': 'slopesign',
				'type': 'raster',
				'source': 'slopesign',
				'paint': {
					'raster-opacity' : 0.5
				},
				'layout': {
					'visibility':'none'
				}
		}, 'contour');
		
		// add a sky layer that will show when the map is highly pitched
		map.addLayer({
		'id': 'sky',
		'type': 'sky',
		'paint': {
		'sky-type': 'atmosphere',
		'sky-atmosphere-sun': [0.0, 0.0],
		'sky-atmosphere-sun-intensity': 15
		}
		});
		

    //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
    function calcCrow(lat1, lon1, lat2, lon2) 
    {
      var R = 6378160 ; // m
      var dLat = toRad(lat2-lat1);
      var dLon = toRad(lon2-lon1);
      var lat1 = toRad(lat1);
      var lat2 = toRad(lat2);

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c;
      return d;
    }

    // Converts numeric degrees to radians
    function toRad(Value) 
    {
        return Value * Math.PI / 180;
    }
	/*const markerMin = new mapboxgl.Marker({
		color: 'red'
		})
		.setLngLat(map.getCenter())
		.addTo(map);

	const markerMax = new mapboxgl.Marker({
		})
		.setLngLat(map.getCenter())
		.addTo(map);
		*/
		// Center the map on the coordinates of any clicked circle from the 'circle' layer.
		const elevationListener = (e) => {
				// Sample the terrain elevation. We round to an integer value to
				// prevent showing a lot of digits during the animation
				var ptMin=null;
				var ptMax=null;
				var meter=1e-5;
				for (var lat=e.lngLat.lat- 5*meter;lat<e.lngLat.lat + 5*meter; lat+=meter){
					for (var lng=e.lngLat.lng- 5*meter;lng<e.lngLat.lng + 5*meter; lng+=meter){
						var pt = {lat : lat, lng:lng};
						pt.elevation= map.queryTerrainElevation(pt, { exaggerated: false })
						if (null == ptMin || pt.elevation<ptMin.elevation){
							ptMin = pt;
						}
						if (null == ptMax || pt.elevation>=ptMax.elevation){
							ptMax = pt;
						}
					}
				}
									
				const elevation =( ptMax.elevation + ptMin.elevation )/ 2;
				var distance=calcCrow(ptMax.lat, ptMax.lng, ptMin.lat, ptMin.lng) ;
				var grandient = Math.atan2(ptMax.elevation- ptMin.elevation, distance) * 360 / (2 * Math.PI);
				
				// markerMin.setLngLat(ptMin);
				 //markerMax.setLngLat(ptMax);
				 
				// Update the popup altitude value and marker location
				altitudeDiv.innerHTML = ('Altitude: ' + Math.floor(elevation) + 'm<br/>' + 'Pente: ' + Math.floor(grandient) + '<br/>');
			};
			map.on('click', elevationListener);
			map.on('mousemove', elevationListener);
				
		/*fetch("gpx/pic-du-rognolet-lauziere.gpx")
		.then(res => res.text())
        .then(str => (new window.DOMParser()).parseFromString(str, "text/xml"))
		.then( res => toGeoJSON["gpx"]( res ) )
		.then( res => {
				map.addSource('gpx', {
						'type': 'geojson',
						'data': res
						});
						
				map.addLayer({
					'id': 'gpx',
					'type': 'line',
					'source': 'gpx',
					'layout': {},
					'paint': {
						'line-color': 'red',
						'line-width': 3
					}
				});
						
		});
	  
	  */
	  
	  
		fetch("gpx/avalanches.json")
		.then(res => res.json())
		.then( res => {
				var geoJSON = { 'type': 'FeatureCollection', 'features': [] };
				for (var i in res) {
					var avalanche = res[i];
					if (avalanche.latitude) {
						var avalancheGeoJson = {
							'type': 'Feature',
							'geometry': {
							'type': 'Point',
							'coordinates': [
								avalanche.longitude,
								avalanche.latitude						
								]
							},
							'properties': {
							}
						}
						for (var prop in avalanche){
							if (avalanche[prop] != null)
							avalancheGeoJson.properties[prop]= avalanche[prop];
						}
						geoJSON.features.push(avalancheGeoJson);
					};
				}
				//https://www.visugpx.com/download.php?id=nlcCd7Mf5J

				map.addSource('avalanches', {
						'type': 'geojson',
						'data': geoJSON
						});
						
				map.addLayer({
					'id': 'avalanches',
					'type': 'circle',
					'source': 'avalanches',
					'layout': {},
					'paint': {
					'circle-color':'blue',
					'circle-radius':10,
					'circle-opacity':0.5
					},
				'layout': {
					'visibility':'none'
				}
				});
				
				
			
				
				// When a click event occurs on a feature in the places layer, open a popup at the
				// location of the feature, with description HTML from its properties.
				map.on('click', 'avalanches', function (e) {
					var coordinates = e.features[0].geometry.coordinates.slice();
					var description = e.features[0].properties.description|| "";
					var date = e.features[0].properties.date;
					var origine_principale = e.features[0].properties.origine_principale|| "";
					var risque_meteo_france = e.features[0].properties.risque_meteo_france || "";
					var link = "http://www.data-avalanche.org/avalanche/" + e.features[0].properties.id || "";
					 http://www.data-avalanche.org/avalanche/1617554382779
					// Ensure that if the map is zoomed out such that multiple
					// copies of the feature are visible, the popup appears
					// over the copy being pointed to.
					while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
					coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
					}
					
					 var html = ReactDOMServer.renderToStaticMarkup( 
					 <div> 
						<div>Le { Intl.DateTimeFormat("fr-FR").format(new Date(date)) } </div>
						<div> {description} </div>
						<div> {origine_principale} </div>
						<div> Risque {risque_meteo_france} </div>
						<div> <a href={link} target="_blank"> Lien </a> </div>
					</div> );
					
					new mapboxgl.Popup()
					.setLngLat(coordinates)
					.setHTML(html)
					.addTo(map);
				});

				// Change the cursor to a pointer when the mouse is over the places layer.
				map.on('mouseenter', 'avalanches', function () {
					map.getCanvas().style.cursor = 'pointer';
				});
				 
				// Change it back to a pointer when it leaves.
				map.on('mouseleave', 'avalanches', function () {
					map.getCanvas().style.cursor = '';
				});

				map.addControl(new PitchToggle(), "bottom-left");

						
		});
		
	});

	
    // Clean up on unmount
    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div className='map-container' ref={mapContainerRef} />
    </div>
  );
};

export default Map;
